@use '../../assets/styles/mixins' as mixin;

.footer {
  background-color: var(--gray-1);
  color: white;
  height: 50px;
  @include mixin.xs {
    font-size: 14px;
  }
}
