@use '../../assets/styles/mixins' as mixin;

.header {
  position: relative;
  padding: 10px 20px;
  height: 50px;
  background-color: #fff;
  box-shadow: var(--box-shadow-nav);
  color: var(--primary);
  img {
    width: 100px;
    @include mixin.md-lt {
      width: 70px;
    }
  }
}

.headerList {
  @media screen and (max-width: 610px) {
    display: none;
  }

  > a {
    text-decoration: none;
    font-weight: 500;
  }
}

.headerXs {
  display: none !important;
  cursor: pointer;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.8;
  }
  @include mixin.xs {
    display: block !important;
  }
}

.login-links {
  text-decoration: none;
  font-weight: 500;
}
.links {
  background-color: #fff;
  color: var(--gray-1);
}

.active {
  color: var(--primary);
}
nav > ul {
  display: flex;
  @include mixin.xs {
    display: block;
  }
}
