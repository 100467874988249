.toast {
  width: 200px;
  padding: 4px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #fff;
}

.success {
  background-color: var(--green-1);
}

.danger {
  background-color: var(--danger);
}

.default {
  background-color: var(--text-color);
}
