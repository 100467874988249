@use './mixins' as mixin;

.btn {
  padding: 5px 15px;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s opacity;
  &:hover {
    opacity: 0.8;
  }
}

.btn-primary {
  background-color: var(--primary);
  color: #fff;
  border: 2px solid var(--primary);
}

.btn-reverse-primary {
  border: 2px solid var(--primary);
  color: var(--primary);
  background-color: #fff;
}
.btn-reverse-danger {
  border: 2px solid var(--red-1);
  color: #fff;
  background-color: var(--red-1);
}
.card {
  border-radius: 20px;
  background-color: #fff;
}
.text-primary, .active {
  color: var(--primary);
}
.border-primary{
  border: 3px dashed var(--primary);
}