:root {
  --primary: #ff6348;
  --red-1: #e82210;
  --gray-1: #20232a;
  --gray-2: #efefef;
  --green-1: #63ce42;
  --font-family: 'Montserrat', sans-serif;
  --box-shadow: 0 6px 9px 0 rgb(0 0 0 / 15%);
  --box-shadow-nav: rgb(0 0 0 / 22%) 0px 2px 18px 0px;
  --text-color: #444;
}
