@use './mixins' as mixin;
body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--gray-2);
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

h1 {
  @include mixin.xs {
    font-size: 14px;
    text-align: center;
  }

  @include mixin.sm-lt {
    h1 {
      font-size: 18px;
    }
  }

  @include mixin.sm {
    font-size: 20px;
    text-align: center;
  }
}

@include mixin.md {
  h1 {
    font-size: 26px;
  }
}

h2 {
  font-size: 22px;
  @include mixin.xs {
    font-size: 10px;
  }
  @include mixin.sm-lt {
    font-size: 14px;
  }
  @include mixin.md {
    font-size: 20px;
  }
}

h3 {
  font-size: 1rem;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding-left: 0px;
  margin: 0;
}

a {
  color: var(--primary);
  text-decoration: none;
}

img {
  max-width: 100%;
}
input {
  font-family: var(--font-family);
}

::placeholder {
  font-family: var(--font-family);
}
::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: var(--gray-2); /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary); /* color of the scroll thumb */
  border-radius: 20px;
  border: 3px solid var(--gray-2);
}

.auth-body-wrapper {
  height: calc(100vh - 100px);
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}
