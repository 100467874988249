.spinner {
  font-size: 40px;
  color: var(--primary);
  animation: rotate 2s infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
