.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}
.pt-5 {
  padding-top: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}

.m-5 {
  margin: 5px;
}

.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-5 {
  margin-right: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}
.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.m-20 {
  margin: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}

.text-center {
  text-align: center;
}

.calc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auth-form {
  max-width: 350px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.auth-form-inputs {
  padding: 6px 9px;
  border: 2px solid var(--gray);
  border-radius: 20px;
  background-color: var(--gray-2);
}
.auth-form-items {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  height: 80px;
}
.auth-form-inputs {
  padding: 6px 9px;
  border: 2px solid var(--gray);
  border-radius: 20px;
  background-color: var(--gray-2);
}

.auth-form-error {
  color: var(--red-1);
  font-weight: 600;
  margin: 10px 0px 0px;
  font-size: 0.9rem;
}
