.MenuContainer {
  position: absolute;
  top: 68px;
  right: 10px;
  width: 200px;
  z-index: 3;
  li {
    padding: 5px 0px 5px 20px;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 0.2s, color 0.2s;
    width: 100%;
    &:hover {
      background-color: var(--primary);
      color: #fff;;
      a {
        color: #fff;;
      }
    }
  }
}